<template>
    <AdminToast />
    <ConfirmDialog></ConfirmDialog>
    <div class='grid'>

        <OvertimeReport :report='report'></OvertimeReport>


        <div class='col-12 md:col-6 lg:col-12'>
            <div class='p-0 h-full flex flex-column'>

                <div class='grid mb-3'>
                    <div class='col-12 lg:col-2'>
                        <h5 class='mb-0 mt-3'><i class='pi pi-wallet mr-2 ml-2'></i> Projedeki Mesai Kayıtları</h5>
                    </div>

                    <div class='col-12 lg:col-10 text-right'>
                        <Calendar id='selectedMonth' view='month' dateFormat='MM - yy' :showIcon='true'
                                  :showButtonBar='true' v-model='selectedSalaryDate'
                                  @date-select='salaryDateChanged'></Calendar>
                    </div>
                </div>

                <DataTable :value='overtimeList' class='p-datatable-gridlines' :rows='10' dataKey='id' :rowHover='true'
                           :paginator='true' :loading='loadingIndicator' responsiveLayout='scroll'>

                    <template #empty>
                        Projede Mesai Kaydı Bulunamadı.
                    </template>

                    <template #loading>
                        Projedeki Mesai Kayıtları Yükleniyor. Lütfen Bekleyin.
                    </template>

                    <Column header='Profil' bodyClass="text-center" style='width:4rem'>
                            <template #body='{data}'>
                                <img :alt='data.employeePhoto' :src="'http://image.a1catering.com.tr/' + data.employee.employeePhoto" class='border-circle border-0 border-solid cursor-pointer' width='80' height='80' style='vertical-align: middle' @click='edit(data)'/>
                            </template>
                    </Column>

                     <Column field='employee.name' header='Ad' style='width:12rem'>
                        <template #body='{data}'>
                                <a @click='edit(data)' class="cursor-pointer">
                                    {{ data.employee.name }}
                                </a>
                        </template>
                    </Column>

                    <Column field='employee.surName' header='Soyad' style='width:12rem'>
                        <template #body='{data}'>
                                <a @click='edit(data)' class="cursor-pointer">
                                    {{ data.employee.surName }}
                                </a>
                        </template>
                    </Column>

                    <Column field='overtimeDate' header='Tarih' style='width:9rem'>
                        <template #body='slotProps'>
                            {{ formatDate(slotProps.data.overtimeDate) }}
                        </template>
                    </Column>

                    <Column field='hour' header='Toplam Mesai Saati' style='width:9rem' class="font-bold">
                    </Column>

                    <Column field='hourlyPrice' header='Saatlik Ücret' style='width:9rem'>
                        <template #body='{data}'>
                            <span class='text-red-600 font-bold'>{{ formatPrice(data.hourlyPrice) }} &#8378;</span>
                        </template>
                    </Column>

                    <Column field='totalPrice' header='Toplam Ücret' style='width:9rem'>
                        <template #body='{data}'>
                            <span class='text-green-600 font-bold'>{{ formatPrice(data.totalPrice) }} &#8378;</span>
                        </template>
                    </Column>

                    <Column field='type' header='Mesai Tipi' style='width:9rem'>
                    </Column>

                    <Column header='Açıklama' filterField='comment'>
                        <template #body='{data}'>
                            {{ data.comment }}
                        </template>
                    </Column>

                    <Column header='Görüntüle' bodyClass='text-center' style='width:6.5rem'>
                        <template #body='slotProps'>
                            <Button icon='pi pi-search' class='p-button-rounded p-button-primary mr-2'
                                    @click='edit(slotProps.data)' />
                        </template>
                    </Column>

                </DataTable>

            </div>
        </div>

    </div>

</template>

<script>


import { getActiveProjectId, normalizeDate } from '../common/commonConstantFunctions';
import moment from 'moment';
import EmployeeOvertimeService from '../../services/employeOvertimeService';
import AdminToast from '../../components/shared/toast/adminToast';
import OvertimeReport from '../../components/shared/OvertimeReport';

export default {
    components: { OvertimeReport, AdminToast },
    _employeeOvertimeService: null,

    created() {
        this.selectedSalaryDate = moment().toDate();
        this.month = moment(this.selectedSalaryDate).toDate().getMonth() + 1;
        this.year = moment(this.selectedSalaryDate).toDate().getFullYear();
        this._employeeOvertimeService = new EmployeeOvertimeService();
        this.projectId = getActiveProjectId();
    },

    async mounted() {
        await this.getEmployeeOvertimeList();
    },
    data() {

        return {
            selectedSalaryDate: null,
            year: 1900,
            month: 1,
            loadingIndicator: false,
            overtimeList: [],
            report: {
                totalHour: 0,
                totalPrice: 0,
                totalItemCount: 0,
            },
        };
    },
    methods: {
        async getEmployeeOvertimeList() {
            this.loadingIndicator = true;

            let startDate = normalizeDate(moment(this.selectedSalaryDate).startOf('month').toDate());
            let endDate = normalizeDate(moment(this.selectedSalaryDate).endOf('month').toDate());
            let overtimeResponse = await this._employeeOvertimeService.getProjectOvertimes(this.projectId, startDate, endDate);
            if (overtimeResponse.isSuccess) {
                this.overtimeList = overtimeResponse.data;
                await this.getEmployeeOvertimeReport();
            }
            this.loadingIndicator = false;
        },
        async getEmployeeOvertimeReport() {
            this.loadingIndicator = true;

            let startDate = normalizeDate(moment(this.selectedSalaryDate).startOf('month').toDate());
            let endDate = normalizeDate(moment(this.selectedSalaryDate).endOf('month').toDate());
            let overtimeResponse = await this._employeeOvertimeService.getProjectOvertimeReport(this.projectId, startDate, endDate);
            if (overtimeResponse.isSuccess) {
                this.report = overtimeResponse.data;
            }
            this.loadingIndicator = false;
        },
        async salaryDateChanged() {
            this.loadingIndicator = true;
            this.month = moment(this.selectedSalaryDate).toDate().getMonth() + 1;
            this.year = moment(this.selectedSalaryDate).toDate().getFullYear();
            await this.getEmployeeOvertimeList();
        },
        formatDate(value) {
            let newDate = moment(value);
            return newDate.format('DD.MM.YYYY');
        },
        formatPrice(value) {
            let val = (value / 1).toFixed(0).replace('.', ',');
            return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
        },
        edit(item) {
            this.$router.push({
                name: 'overtimeProjectEmployee',
                params: { employeeId: item.employeeId, projectEmployeeId: item.projectEmployeeId },
            });
        },
    },
};
</script>

<style scoped lang='scss'>
//@import '@/assets/demo/styles/badges.scss';

::v-deep(.p-datatable-frozen-tbody) {
    font-weight: bold;
}

::v-deep(.p-datatable-scrollable .p-frozen-column) {
    font-weight: bold;
}

::v-deep(.p-progressbar) {
    height: .5rem;
    background-color: #D8DADC;

    .p-progressbar-value {
        background-color: #607D8B;
    }
}
</style>
