<template>

    <div class='col-12 md:col-6 lg:col-4'>

        <div class='surface-card shadow-0 p-3 border-round' style='border:1px solid #dfe7ef'>
            <div class='flex justify-content-between mb-3'>
                <span class='block font-medium mb-3 text-xl'>Toplam Mesai Saati</span>
                <div class='flex align-items-center justify-content-center border-round' style='width:2.5rem;height:2.5rem'>
                    <i>
                        <img src='/layout/images/icon/saat.svg' height='54' class='mt-2 mr-2' />
                    </i>
                </div>
            </div>
            <div class="mt-2">
                <div class='text-900 font-bold text-xl'>
                   {{ report.totalHour }}<span class='font-light ml-1'>Saat</span>
                </div>
            </div>
        </div>
    </div>

    <div class='col-12 md:col-6 lg:col-4'>
        <div class='surface-card shadow-0 p-3 border-round' style='border:1px solid #dfe7ef'>
            <div class='flex justify-content-between mb-3'>
                <span class='block font-medium mb-3 text-xl'>Toplam Mesai Ücreti</span>
                <div class='flex align-items-center justify-content-center border-round' style='width:2.5rem;height:2.5rem'>
                    <i>
                        <img src='/layout/images/icon/toplam_mesai.svg' height='54' class='mt-2 mr-2' />
                    </i>
                </div>
            </div>
            <div class="mt-2">
                <div class='text-900 font-bold text-xl'>
                    {{ formatPrice(report.totalPrice) }}<span class='font-light ml-1'>&#8378;</span>
                </div>
            </div>
        </div>
    </div>

    <div class='col-12 md:col-6 lg:col-4'>
        <div class='surface-card shadow-0 p-3 border-round' style='border:1px solid #dfe7ef'>
            <div class='flex justify-content-between mb-3'>
                <span class='block font-medium mb-3 text-xl'>Toplam Kayıt</span>
                <div class='flex align-items-center justify-content-center border-round' style='width:2.5rem;height:2.5rem'>
                    <i>
                        <img src='/layout/images/icon/server.svg' height='54' class='mt-2 mr-2' />
                    </i>
                </div>
            </div>
            <div class="mt-2">
                <div class='text-900 font-bold text-xl'>
                    {{ report.totalItemCount }}<span class='font-light ml-1'>Adet</span>
                </div>
            </div>
        </div>
    </div>

</template>



<script>
export default {
    name: 'OvertimeReport',
    props: {
        report: {},
    },
    methods:{
        formatPrice(value) {
            let val = (value/1).toFixed(2).replace('.', ',')
            return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
        },
    }
};
</script>